
.card-img{
    width: 160px;
    height: 160px;
    position: relative;
    border-radius: 5px;
    border: 1px solid #edecec;
    
}
.card-img img{
    width: 100%;
    height: 100%;
   
    border-radius: 5px;
}
#hover-img{
    position: absolute;
    top: 0;
    visibility: hidden;
    transform: rotateY(90deg);
    transition: .5s;
    opacity: 0;
}
.card-img:hover img{
    visibility: hidden;
}
.card-img:hover #hover-img{
    visibility: visible;
    transform: rotateY(180deg);
    opacity: 10;

}
.reting i{
color: #ffa145;
font-size: 11px;
height: 13px;
margin-right: 4px;
margin-bottom: 10px;
}
.reting i:nth-child(4){
    color: #d6d6d6;
}
.reting i:nth-child(5){
    color: #d6d6d6;
}
.spac-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
}
.card-img:hover .spac-icon{
    display: block;
}
.spac-icon i{
    display: block;
    color: black;
    margin: 7px 0px;
}
.main-card a{
    text-decoration: none;
}
.main-card{
    height: 300px;
}
.inner-content p{
    text-align: left;
    font-size: 14px;
    color: #7f7355;
    text-decoration: none;
    margin-bottom: 10px;
}
.regular-price{
    color: #ff8a00;
    font-weight: 600;
    font-size: 16px;
    margin-right: 6px;
   
}
.cut-price{
    color: #878787;
    display: inline-block;
    font-weight: 400;
    text-decoration: line-through;
    vertical-align: top;
    font-size: 15px;
}
.addtocart-btn{
    position: absolute;
    display: none;
    top: 5px;
}
.addtocart-btn a{
    background: linear-gradient(to bottom,#ffffff 0%,#eae5d9 100%);
    border: 1px solid #e3dfdf;
    filter: none;
    color: #7f7355;
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 14px;
}
.addtocart-btn a:hover{
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    color: white;
    border: transparent
}
.main-price{
    position: relative;
    z-index: 1 !important;
    
}
.main-card:hover .addtocart-btn{
    display: block;
}
.main-card:hover .regular-price{
    display: none;
}
.main-card:hover .cut-price{
    display: none;
}
.addtocart-btn{
    cursor: pointer;
}
@media(max-width: 900px){
 .card-img{
    z-index: -1;
 }
}
@media(max-width: 500px){
    .card-img{
        width: 150px;
        height: 150px;
    }
}
@media(max-width: 360px){
    .card-img{
        width: 130px;
        height: 130px;
    }
}