.owl-theme{
    background: #f6f3eb;
    border: 1px solid #e2dcc8;
}
.item{
    display: flex;
 justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    
}

.owl-nav{
    position: absolute;
    top: -69px;
    right: 0;
    z-index: 1;
}
.owl-nav button span{
    display: block;
   width: 25px;
   border: 1px solid #eeeade;
   background-color: #f6f3eb;
   border-radius: 5px;
    height: 25px;
    line-height: 20px;
    font-size: 25px;
    color: #bcb4a1;
}
.owl-nav button span:hover{
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    color: white;
    border: transparent
}

@media(max-width: 900px){
    .owl-carousel.owl-loaded{
        height: 304px;
        overflow: hidden;
        
    }
    .owl-nav{
        top: 243px;
        left: 0px;
        right: 0px;
    }
  
    @media(max-width: 500px){
        .item{
            flex-wrap: wrap;
            
        }
    }
}
