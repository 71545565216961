.recent-pro{
    display: flex;
}
.pr-img{
    width: 50px;
    height: 50px;
    border: 1px solid #EEEADE;
}
.pr-img img{
    width: 100%;
    height: 100%;
}
.price h4{
    text-align: left;
    font-size: 14px;
    color: #7f7355;
    margin: 0px;
}
.price{
    margin-left: 10px;
}
.regular-pr{
    color: #ff8a00;
    font-weight: 700;
    font-size: 14px;
    margin-right: 5px;
    
}
.cut-price{
    color: #878787;
    display: inline-block;
    font-weight: 400;
    text-decoration: line-through;
    vertical-align: top;
    font-size: 13px;
}
.main-price{
    display: flex;
    align-items: center;
}
.bt a{
    margin-top: 10px;
    line-height: 17px;
    padding: 7px 12px;
    text-decoration: none;
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    display: inline-block;
}
