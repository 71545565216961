.general-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.main-price{
    z-index: -1;
}
@media(max-width: 900px){
    .general-box{
        padding: 20px;
    }
}