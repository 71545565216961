.left{
    width: 20%;
}
.center{
    width: 55%;
    
}
.right{
    width: 20%;
}
.my-ct{
    margin-top: 40px;
    align-items: start;
}
.mycontainer{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    border-top: 1px solid #A0CE41 !important;
    box-shadow: 0 1px 0 #C8E861 inset;
    background: linear-gradient(to bottom,#a1ce41 0%,#6aad38 100%);
    height: 75px;
    align-items: center;
    padding: 0pc 30px;
    margin-top: 60px;
}
.ms{
    display: flex;
    align-items: center;
}
.ms i{
    font-size: 40px;
    color: white;
}
.inner-comp{
    width: 70%;
}
.ms p{
    margin: 0px;
    color: white;
    font-size: 25px;
    margin: 0px 20px;
}
.submitms{
    display: flex;
}
.submitms .sub{
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    filter: none;
    color: #fff;
    border-color: #ffa145;
    margin: 0px 20px;
    width: 30%;
}
.submitms input{
    width: 100%;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    background-color: white;
    font-size: 15px;
    border: none;
    padding: 10px;
}
.inner-comp2 i{
    height: 40px;
    width: 40px;
    background-position: 5px 7px;
    cursor: pointer;
    margin-right: 4px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top: 1px solid #bcde55;
    box-shadow: 0 0 0 0 #bcde55 inset;
    background-color: #669e2c;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: white;
}
.inner-comp2 i:hover{
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
}


@media(max-width: 900px){
    .my-ct{
        display: block;
    }
    .right{
        display: none;
    }
  
    .center{
        width: 100%;
    }
    .mycontainer{
        width: 100%;
    }
    .inner-comp{
        display: none;
    }
    .inner-comp2{
        width: 100%;
        text-align: center;
    }
}