.newcenter{
    width: 78% !important;
}
.inner-boxes{
    width: 48%;
}
.primge{
    border-radius: 5px;
    border: 1px solid #eeeade;
    width: 100%;
    height: 100%;
}
.primge img{
    width: 100%;
    height: 100%;
}
.main-back{
    display: flex;

justify-content: space-between;


}
.backimg{
    width: 100px;
    height: 100px;
    border: 1px solid #eeeade;
}
.backimg img{
    width: 100%;
    height: 100%;
}
.ct{
    display: flex;
    justify-content: space-between;
}
.addtocart a{
    padding: 7px 15px;
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    filter: none;
    color: #fff;
    border-color: transparent;
    text-decoration: none;
    border-radius: 5px;
}
.qantiti{
    display: flex;
    gap: 40px;
}
.inner-qantiti{
    display: flex;
    align-items: center;
    gap: 7px;
}
.decrise, .incrice{
    font-size: 15px;
    width: 25px;
    height: 25px;
    border: 1px solid #eeeade;
    text-align: center;
    line-height: 25px;
}
.qwantitival{
    width: 90px;
    height: 40px;
    border: 1px solid #eeeade;
    line-height: 40px;
    text-align: center;
}
.prodect-inner span > a{
    text-decoration: none;
    font-size: 14px;
    color: #7f7355;
    position: relative;
    padding-right: 5px;
   
}
.prodect-inner span >a:hover{
    color: #ffa145;

}
.prodect-inner span:nth-child(2){
       margin-right: 5px;
}
.prodect-inner span:nth-child(4){
    margin-left: 5px;
}
.prodect-inner span:nth-child(3){
    text-decoration: none;
    font-size: 14px;
    color: #7f7355;
    position: relative;
margin-left: 5px;
z-index: -1;
}
.prodect-inner span:nth-child(3):hover{
    color: #ffa145;
}
.prodect-inner{
    margin-bottom: 25px;

}
.main-dis h3{
    padding: 10px;
    border: 1px solid #e7e7e7;
    display: inline-block;
    border-bottom: 1px solid white;
    margin: 0px;
}
.dis-box{
    border: 1px solid #e7e7e7;
    margin-top: -1px;
    padding: 15px;
}
.dis-box p{
    color: #7f7355;
    font-size: 14px;
    line-height: 1.6;
}
.inner-boxes2{
    width: 45%;
    margin-top: 40px;
}
.inner-boxes2 > span{
    color: #ff8a00;
    font-size: 15px;
    font-size: 22px;
}
.benefit > ul > li{
    font-size: 15px;
    line-height: 1.9;
    color: #7f7355;
}
.inner-qantiti span{
    color: #7f7355; 
}
.addtocart{
    margin-top: 25px;
}
@media(max-width: 900px){
    .newcenter{
        width: 100% !important;
    }
    .ct{
        display: block;
        width: 100%;
    
    }
    .inner-boxes{
        width: 100%;
        margin: auto;
    }
    .inner-boxes2{
        width: 80%;
        margin: auto;
        margin-top: 70px;
    }
    .backimg{
        width: 130px;
        height: 130px;
        border: 1px solid #eeeade;
        text-align: center;
        line-height: 130px;
    }
    .main-dis{
        margin-top: 50px;
    }
}
/* .maid-dis{
    width: 80%;
    margin: auto;
} */