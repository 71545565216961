.hd-cotegrise{
    max-width: 100%;
    padding: 0;
    background: url('../image/capsule.png') no-repeat scroll left center;
    padding-left: 20px;
    
  
}
.main-hd{
    background: linear-gradient(#f3eedb,rgba(243,238,219,0));
    padding: 5px;
    margin-top: 5px;
}
.hd-cotegrise h2{
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #706342;
    line-height: 22px;
   
}
.ct-list > ul > li{
    list-style: none;
}
.ct-list > ul > li > a{
    text-decoration: none;
    color: #7f7355;
    background-position: 0 13px;
    background-repeat: no-repeat;
    font-size: 14px;
    padding-left: 17px;
    text-transform: capitalize;
    line-height: 30px;
    background: url(../image/arrow.png);
    background-position: 0 7px;
    background-repeat: no-repeat;
  transition: .2s;
    line-height: 30px;
    
}
.ct-list > ul > li > a:hover{
    background-position: 0 -104px;
    color: #ffa145;
}
.main-left{
    border: 1px solid #EEEADE;
  
}
.ct-list{
    padding: 0px 15px 15px 15px;
}
.in-image{
    margin-top: 30px;
    margin-bottom: 40px;
}
.in-image img{
    width: 100%;
}


@media(max-width: 900px){
    .left{
        height: 100vh;
        position: absolute;
        top: 0px;
        background: white;
        position: fixed;
        z-index: 1;
        width: 55%;
        overflow: auto;
        left: -213px;
        transition: .4s;
    }
    .main-hd{
        display: none;
    }
    .ct-list{
        width: 41%;
    }
    .ct-list > ul > li > a{
        background: none;
        
        display: block;
        width: 100%;
        background: none;
    }
    .ct-list > ul > li{
        width: 130px;
    }
    .main-left{
        border: none;
    }
    .myright{
        display: none;
    }
    .ct-list{
        margin-top: 60px;
    }
}
