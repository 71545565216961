.pr-ct{
    border-radius: 5px 5px 0 0;
    border-top: 1px solid #A0CE41 !important;
    box-shadow: 0 1px 0 #C8E861 inset;
    background: linear-gradient(to bottom,#a1ce41 0%,#6aad38 100%);
    height: 55px;
    position: relative;
}
.inner-nav > ul{
    display: flex;
}
#respon > ul > li{
    list-style: none;
    width: auto;
    position: relative;
    background: url(../image/line.png) no-repeat scroll 0 -9px;
    float: left;

}
.inner-nav > ul > li:first-child{
    background: transparent;
}
#respon > ul > li > a{
    text-decoration: none;
    padding: 45px 15px;
    color: white;
    font-weight: 500;

}
.inner-nav2{
    background: linear-gradient(to bottom,#8db831 0%,#59962b 100%);
    padding:  15px 0px;
    color: white;
    border-top: 1px solid #A0CE41 !important;
    box-shadow: 0 1px 0 #C8E861 inset;
    border-radius: 0px 5px 0 0;
    display: flex;
    align-items: center;
}
.inner-nav2 i{
    margin: 0px 3px;
}
.inner-nav2 span{
    display: block;
    margin: 0px 3px;
    font-size: 15px;
}
.inner-nav2 i:first-child{
    font-size: 18px;
}
.inner-nav2 i:last-child{
    font-size: 10px;
}
#menu{
    width: 25px;
    height: 20px;
    display: none;
}
#menu span{
    width: 100%;
    display: block;
    background-color: white;
    height: 2px;
    margin-top: 4px;
}
.of{
     visibility: hidden;
}

   #main-menu{
    display: none;
   }
   
@media(min-width: 900px){
    .on{
        display: none;
    }
}
@media (max-width: 900px){
.pr-ct{
    width: 100%;
    display: flex;
    padding: 0px 10px;
}
#respon{
    position: absolute;
    background: white;
    width: 137px;
    right: 105px;
    transition: .5s;
    z-index: 999;
    top: 54px;
    box-shadow: 0px 0px 4px 0px #888;
   
    
}

.on{
    top: 0;
    overflow: auto;
    background: white;
    height: 100vh;
    position: fixed;
    left: 0;
    transition: .3s;
    z-index: 999;
}
.of{
  left: -240px;
  position: absolute;

  transition: .3s;

}
.cut span{
    padding: 17px;
    float: right;
    font-size: 25px;
    
}

.up{

    position: absolute;
    height: 140px;
    visibility: visible;
    opacity: 10;
   }
   .down{
     
       position: absolute;
       height: 0;
       padding: 0;
       visibility: hidden;
       opacity: 0;
   }


#main-menu{
    display: block;
    margin-right: 20px;
}
#main-menu i{
    font-size: 23px;

}

 .inner-nav > ul{

    position: absolute;
    background-color: white;
    display: block;
    right: 117px;
    top: -162px;
    position: absolute;
    background-color: white;
    width: 145px;
    padding: 20px;
 }
 #respon > ul > li{
    float: none;
    background: transparent;
    transition: .4s;
    padding: 0px 20px;
 }
 #respon > ul > li > a{
    color: black;
    margin-top: 20px;
    padding: 0px;
    line-height: 1.9;
    font-size: 15px;
    transition: .4s;
    
 }
 .h{
    height: 25px;
 }
 .cuth{
    height: 0px;
 }
 .inner-nav > ul > li > a:hover{
    color: #ffa145;
 }
 #dn{
    display: none;
 }
 .inner-nav2 .fa-chevron-down{
    display: none;
 }
 #menu{
    display: block;
 }
 .my-ct{
    z-index: -1;
 }
 
}