*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.main{
    background: linear-gradient(to bottom,#efebdd 0%,#f6f4ed 66%,#ebe7d9 100%);
    
    padding-top: 30px;
}
.cont{
    width: 80%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
}
.inner-box{
 width: 20%;
}
.inner-box2{
    width: 25%;
   }
.inner-box img{
    width: 100%;
}
.inner-input{
    background-color: #9d9073;
    padding: 6px;
    position: relative;
    display: flex;
    border-radius: 5px;
}
.inner-input input{
   width: 100%;
   padding: 5px;
   outline: none;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
   background-color: white;
   font-size: 15px;
   border: none;
}
.search{
    background: linear-gradient(to bottom,#1e5799 0%,#fe8b1a 0%,#ffa145 100%);
    padding: 8px;
    border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
   color: white;
}
@media  (max-width: 900px){
  .main{
    width: 100%;
  }
  .cont{
    width: 100%;
    display: block;
  }
  .inner-box{
    margin: auto;
    width: 40%;
  }
  .inner-input{
    display: none;
  }

}