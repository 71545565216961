
.mainfooter{
    background-color: #f1eee2;
    padding:  30px 0px;
}
#sec1 img{
    width: 70%;
    margin-bottom: 10px;
    
}
#sec1 li{
    list-style: none;
}
#sec2 li{
    list-style: none;
}
#sec3 li{
    list-style: none;
}
#sec4 li{
    list-style: none;
}
.mmc ul{
    width: 22%;
}
.mmc{
    align-items: start;
}
.mmc > ul > li > h2{
    color: #706342;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;

}
.mmc > ul > li > p{
    font-size: 14px;
    color: #7f7355;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2;
}
.mmc > ul > li{
    font-size: 14px;
    color: #7f7355;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2;
}
.mmc > ul > li > span{
    display: block;
}
@media(max-width: 900px){
    .mmc{
        padding: 10px 30px;
    }
    .mmc ul {
        width: 82%;
    }
    
}