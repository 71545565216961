
.main-inner-box{
    position: absolute;
    top: 50%;
    width: 80%;
    margin: auto;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    
}
.my{
    position: relative;
}
.innertext h3{
    text-transform: capitalize;
    font-weight: 400;
    color: #484236;
    letter-spacing: 0;
    font-size: 16px;
  margin-bottom: 15px;

}
.innertext h2{
    text-transform: capitalize;
    font-weight: 600;
    color: #484236;
    letter-spacing: 0;
    font-size: 26px;
    width: 300px;
    margin-bottom: 25px;

}
.innertext a{
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    filter: none;
    padding: 9px 18px;
    text-transform: capitalize;
    text-decoration: none;
    
}
.my-bt{
    visibility: hidden;
}
.main-sl:hover .my-bt{
    visibility: visible;
    color: #fe8b1a;
    font-size: 25px;
}
.mi-text{
    margin: 30px 0px;
}
.mi-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mi-text h2{
    margin: 0px;
}
.leftright-button{
    display: flex;
}
.leftright-button span{
    display: block;
    padding: 4px 5px;
    border: 1px solid #eeeade;
    background-color: #f6f3eb;
    text-align: center;
    color: #7a7979;
    margin-left: 10px;
}
.leftright-button span:hover{
    background: linear-gradient(to bottom,#ffa145 0%,#fe8b1a 100%);
    color: white;
}
.prod{
    margin-top: 40px;
}
.freedelevery{
    margin-top: 30px;
}
.about-img img{
    width: 100%;
}
#about-heading{
    padding: 10px 0px;
    padding-top: 30px;
}
#about-para{
    color: #7f7355;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: .4px;
}
.flbx{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 20px;
}
#hover-img{
    left: 0px;
}
@media  (max-width: 900px){
    .freedelevery img{
        width: 100%;
    }
    .inner-nav2{
        background: transparent;
    }
   
    .my img{
        width: 100%;
    }
    .innertext h2{
        font-size: 19px;
    }
   
}